import React from "react";
import { useOrg } from "../../context/OrgContext";

function Header(props) {
  const { logo } = useOrg();

  return (
    <div className="bg-white h-20 shadow-inner p-3 px-10 w-full flex justify-between">
      <div className="flex-auto w-full flex  items-center">
        {logo && <img alt="evolve logo" src={logo} width="80" height="80" />}
      </div>
      <div className="flex">{/* links will go here */}</div>
    </div>
  );
}

export default Header;
