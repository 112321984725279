import { createContext, useContext, useState } from "react";

const OrgContext = createContext();

function OrgProvider({ children, ou_ref, co_ref }) {
  const [data, setData] = useState({});
  const [downloadForm, setDownloadForm] = useState(false);

  const value = {
    styles: { footer: { background: data?.theme_color, color: "white" } },
    logo: data?.logo,
    footerText: data?.name,
    color: data?.theme_color,
    setData,
    setDownloadForm,
    downloadForm,
  };

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>;
}

const useOrg = () => {
  const context = useContext(OrgContext);

  if (context === undefined) {
    throw new Error("useOrg must be used within an OrgProvider");
  }

  return context;
};

export { OrgProvider, useOrg };
