import React from "react";
import Chart from "../assets/svg/chart.svg";
import Love from "../assets/svg/love.svg";
import Coin from "../assets/svg/coin.svg";
import Calender from "../assets/svg/calender.svg";
import { Card, Col, Row } from "antd";

function Landing(props) {
  return (
    <div className="h-screen flex items-center">
      <section className="w-2/3 m-auto h-2/3  relative">
        <img src={Chart} alt="chart icon" className="absolute left-0 top-0" />
        <img src={Love} alt="chart icon" className="absolute top-0 right-0" />
        <img src={Coin} alt="chart icon" className="absolute bottom-0 left-0" />
        <img
          src={Calender}
          alt="chart icon"
          className="absolute bottom-0 right-0"
        />
        <div className="h-full flex items-center justify-center">
          <h1 className="text-3xl text-extrabold text-center text-purple-900">
            Evolve Credit Services
          </h1>
        </div>
      </section>
    </div>
  );
}

export default Landing;
