import React from "react";
import useSWR from "swr";
import { useLocation } from "react-router-dom";
import { Request } from "../../utils/Axios";
import GetMandateForm from "./GetMandateForm";
import RequestOTP from "./RequestOTP";
import { useOrg } from "../../context/OrgContext";
function DebitMandate(props) {
  const query = new URLSearchParams(useLocation().search);
  const { data: mandate, error } = useSWR(
    `/test/credit-order/mandate/form/${query
      .get("co-ref")
      ?.trim()}/is-otp-supported`,
    (url) => Request.get(url)
  );

  const { downloadForm } = useOrg();
  if (false) {
    // Dead end, ignore
    console.log(error);
  }
  return (
    <div>
      {!mandate?.data?.data || downloadForm ? (
        <GetMandateForm />
      ) : (
        <RequestOTP />
      )}
    </div>
  );
}

export default DebitMandate;
