export const formatLoanChargeFreq = (frequency) => {
  switch (frequency) {
    case "monthly":
      return "a month";
    case "bi-weekly":
      return "two weeks";
    case "bi-monthly":
      return "two months";
    case "quarterly":
      return "three months";
    case "bi-annually":
      return "two years";
    default:
      return "month";
  }
};

export const formatLoanTurn = (tenure) => {
  switch (tenure) {
    case 1:
      return "once";
    case 2:
      return "twice";
    case 3:
      return "thrice";

    default:
      return `${tenure} times`;
  }
};
