import React, { useState } from "react";
import { Button, Divider, message } from "antd";
import { useLocation } from "react-router-dom";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useOrg } from "../../context/OrgContext";
import { Request } from "../../utils/Axios";

function GetMandateForm(props) {
  const { color } = useOrg();
  const [loadingRequestOtp, setLoadingRequestOTP] = useState(false);
  const query = new URLSearchParams(useLocation().search);

  const handleMandateForm = async () => {
    // This functions for mandate form
    setLoadingRequestOTP(true);
    const GetMandateForm = await Request.get(
      `/test/credit-order/mandate/form/${query
        .get("co-ref")
        ?.trim()}/get-mandate-form`
    );

    if (GetMandateForm?.data?.status === "error") {
      message.error(GetMandateForm?.data?.message);
      return;
    }
    setLoadingRequestOTP(false);
    window.open(GetMandateForm.data.data || "");
  };

  return (
    <div>
      <h1 className="lg:text-xl text-lg font-bold text-center">
        Follow the following steps to authorise your debit mandate.
      </h1>

      <ul className="px-5 my-4">
        <li className="list-decimal text-base my-2">
          Click the button below to download your Direct Debit Mandate form.
        </li>

        <li className="list-decimal text-base my-2">
          Take the printed form to the customer service desk at your local bank
          branch.
        </li>

        <li className="list-decimal text-base my-2">
          Request to “activate a debit mandate” on your account and submit the
          printed form.
        </li>

        <li className="list-decimal text-base my-2">
          Contact Medsaf as soon as the mandate is authorised.
        </li>
      </ul>

      <Divider />

      <Button
        onClick={handleMandateForm}
        loading={loadingRequestOtp}
        type="primary"
        style={{ background: color, width: "100%" }}
        size="large"
      >
        Download Mandate Form
      </Button>
    </div>
  );
}

export default GetMandateForm;
